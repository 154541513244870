import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios.js";

const initialState = {
  plansList: [
    {
      name: "Base Plan",
      id: "palne_a",
      headOne: "100 Mbps",
      headTwo: "$ 45/Month",
      list: [
        "Lorem ipsum dolor sit",
        "Consectetur adipiscing elit",
        "Sed do eiusmod tempor",
        "Icididunt ut labore et dolore",
        "Magna aliqua",
      ],
    },
    {
      name: "Pro Plan",
      id: "palne_b",
      headOne: "300 Mbps",
      headTwo: "$ 50/Month",
      list: [
        "Lorem ipsum dolor sit",
        "Consectetur adipiscing elit",
        "Sed do eiusmod tempor",
        "Icididunt ut labore et dolore",
        "Magna aliqua",
      ],
    },
    {
      name: "Ultimate Plan",
      isMostPopular: true,
      id: "palne_c",
      headOne: "1 Gig",
      headTwo: "$ 66/Month",
      list: [
        "Lorem ipsum dolor sit",
        "Consectetur adipiscing elit",
        "Sed do eiusmod tempor",
        "Icididunt ut labore et dolore",
        "Magna aliqua",
      ],
    },
    {
      name: "Turbo Plan",
      id: "palne_d",
      headOne: "2 Gigs",
      headTwo: "$ 105/Month",
      list: [
        "Lorem ipsum dolor sit",
        "Consectetur adipiscing elit",
        "Sed do eiusmod tempor",
        "Icididunt ut labore et dolore",
        "Magna aliqua",
      ],
    },
    {
      name: "Infinity Plan",
      id: "palne_e",
      headOne: "10 Gigs",
      headTwo: "$ 103/Month",
      list: [
        "Lorem ipsum dolor sit",
        "Consectetur adipiscing elit",
        "Sed do eiusmod tempor",
        "Icididunt ut labore et dolore",
        "Magna aliqua",
      ],
    },
  ],
  selectedPlan: "",
  suggestedPlan: "",
  suggestionFormData: {},
};

export const getAppData = createAsyncThunk("app/get/appData", async () => {
  const response = await axios.get(`/api/v1/customer/getAppData`);
  return response;
});
export const choosePlan = createSlice({
  name: "app/choosePlan",
  initialState,
  reducers: {
    updateSuggestedPlan: (state, action) => {
      state.suggestedPlan = action?.payload;
    },
    updateSelectedPlan: (state, action) => {
      state.selectedPlan = action?.payload;
    },
    updateSuggetionFormData: (state, action) => {
      let currentData = { ...state.suggestionFormData };
      currentData[action?.payload.feild] = action?.payload.data;
      state.suggestionFormData = action?.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAppData.pending, (state) => {
        state.appData.loader = true;
      })
      .addCase(getAppData.fulfilled, (state, action) => {
        state.appData.loader = false;
        state.appData.data = action?.payload?.data?.data;
      })
      .addCase(getAppData.rejected, (state) => {
        state.appData.loader = false;
        state.appData.data = [];
      });
  },
});

export const {
  updateSuggestedPlan,
  updateSelectedPlan,
  updateSuggetionFormData,
} = choosePlan.actions;

export default choosePlan.reducer;
