import { Routes, Route } from "react-router-dom";
//import AuthGuard from "../../../app/auth/AuthGuard";
import AppLayout from "../../layouts/AppLayout";
import { lazy } from "react";
import Loadable from "../../components/customComponents/Loadable";

const NotFound = Loadable(lazy(() => import("../../view/admin/NotFound")));
const CheckAvailabilityPage = Loadable(
  lazy(() => import("../../view/admin/checkAvailability"))
);

const ChoosePlanePage = Loadable(
  lazy(() => import("../../view/admin/ChoosePlan"))
);
const AddOnsPage = Loadable(lazy(() => import("../../view/admin/AddOns")));
const CreateAccountPage = Loadable(
  lazy(() => import("../../view/admin/CreateAccount"))
);
const ActivationPage = Loadable(
  lazy(() => import("../../view/admin/Activation"))
);
const SummaryPage = Loadable(lazy(() => import("../../view/admin/Summary")));
const PaymentPage = Loadable(lazy(() => import("../../view/admin/Payment")));
const RoutePage = () => {
  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}/`} element={<AppLayout />}>
        <Route path="/" element={<CheckAvailabilityPage />} />
        <Route path="/choosePlan" element={<ChoosePlanePage />} />
        <Route path="/addOns" element={<AddOnsPage />} />
        <Route path="/createAccount" element={<CreateAccountPage />} />
        <Route path="/schedule" element={<ActivationPage />} />
        <Route path="/summary" element={<SummaryPage />} />
        <Route path="/makePayment" element={<PaymentPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
export default RoutePage;
