import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios.js";

const initialState = {
  createAccountFormData: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phone_code: "1",
  },
};

export const getAppData = createAsyncThunk("app/get/appData", async () => {
  const response = await axios.get(`/api/v1/customer/getAppData`);
  return response;
});
export const createAccountSLice = createSlice({
  name: "app/createAccountSLice",
  initialState,
  reducers: {
    updateFormOfCreateAccount: (state, action) => {
      const { event, feild } = action?.payload;
      let curentVlaues = { ...state.createAccountFormData };
      curentVlaues[feild] = event;

      state.createAccountFormData = { ...curentVlaues };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAppData.pending, (state) => {
        state.appData.loader = true;
      })
      .addCase(getAppData.fulfilled, (state, action) => {
        state.appData.loader = false;
        state.appData.data = action?.payload?.data?.data;
      })
      .addCase(getAppData.rejected, (state) => {
        state.appData.loader = false;
        state.appData.data = [];
      });
  },
});

export const { updateFormOfCreateAccount } = createAccountSLice.actions;

export default createAccountSLice.reducer;
