import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios.js";

const initialState = {
  addedItems: [],
};

export const getAppData = createAsyncThunk("app/get/appData", async () => {
  const response = await axios.get(`/api/v1/customer/getAppData`);
  return response;
});
export const addOneSlice = createSlice({
  name: "app/addOneSlice",
  initialState,
  reducers: {
    addItemToAddOn: (state, action) => {
      state.addedItems = [...state.addedItems, action?.payload];
    },
    removeItemFromAddOn: (state, action) => {
      let filteredItems = [...state.addedItems].filter(
        (itm) => itm.id !== action?.payload
      );

      state.addedItems = [...filteredItems];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAppData.pending, (state) => {
        state.appData.loader = true;
      })
      .addCase(getAppData.fulfilled, (state, action) => {
        state.appData.loader = false;
        state.appData.data = action?.payload?.data?.data;
      })
      .addCase(getAppData.rejected, (state) => {
        state.appData.loader = false;
        state.appData.data = [];
      });
  },
});

export const { addItemToAddOn, removeItemFromAddOn } = addOneSlice.actions;

export default addOneSlice.reducer;
