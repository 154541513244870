import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios.js";

const initialState = {
  date: "",
  time: {
    option1: false,
    option2: false,
    option3: false,
  },
};

export const getAppData = createAsyncThunk("app/get/appData", async () => {
  const response = await axios.get(`/api/v1/customer/getAppData`);
  return response;
});
export const scheduleSlice = createSlice({
  name: "app/scheduleSlice",
  initialState,
  reducers: {
    updateSelectedScheduleDate: (state, action) => {
      state.date = action?.payload;
    },
    updateSelectedScheduleTimeList: (state, action) => {
      const { event, feild } = action?.payload;
      let curentTime = { ...state.time };
      curentTime[feild] = event;
      state.time = { ...curentTime };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAppData.pending, (state) => {
        state.appData.loader = true;
      })
      .addCase(getAppData.fulfilled, (state, action) => {
        state.appData.loader = false;
        state.appData.data = action?.payload?.data?.data;
      })
      .addCase(getAppData.rejected, (state) => {
        state.appData.loader = false;
        state.appData.data = [];
      });
  },
});

export const { updateSelectedScheduleDate, updateSelectedScheduleTimeList } =
  scheduleSlice.actions;

export default scheduleSlice.reducer;
