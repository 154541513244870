import { combineReducers } from "redux";
import NotificationReducer from "./NotificationReducer";

import appSlice from "../slices/app/appSlice";
import checkAvailability from "../slices/app/checkAvailability";
import choosePlaneSlice from "../slices/app/choosePlaneSlice";
import addOneSlice from "../slices/app/addOneSlice";
import createAccountSLice from "../slices/app/createAccountSlice";
import scheduleSlice from "../slices/app/scheduleSlice";

const RootReducer = combineReducers({
  notifications: NotificationReducer,
  checkAvailability: checkAvailability,
  choosePlaneSlice: choosePlaneSlice,
  addOneSlice: addOneSlice,
  createAccountSLice: createAccountSLice,
  scheduleSlice: scheduleSlice,
  appSlice: appSlice,
});

export default RootReducer;
