import { Outlet } from "react-router-dom";
import MatxSuspense from "../components/customComponents/MatxSuspense";
import Footer from "./Footer/footer";
import Header from "./Header/header";

const AppLayout = (props) => {
  return (
    <div className="wrapperBody">
      <MatxSuspense>
        <MatxSuspense>
          <div className="container-fluid">
            <Header />
            <Outlet />
            <Footer />
          </div>
        </MatxSuspense>
      </MatxSuspense>
    </div>
  );
};

export default AppLayout;
