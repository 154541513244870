import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./reducers/RootReducer";

let devtools = (x) => x;

// if (
//   process &&
//   process.env.NODE_ENV !== 'production' &&
//   process.browser &&
//   window.__REDUX_DEVTOOLS_EXTENSION__
// ) {
//   devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
// }
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return RootReducer({}, action);
  }
  return RootReducer(state, action);
};
export const Store = configureStore({
  reducer: rootReducer,
  devTools: devtools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
