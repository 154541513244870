import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Store } from "./app/redux/Store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./features/assets/styles/js/theam";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "react-perfect-scrollbar/dist/css/styles.css";

import "./index.css";
import ScrollToTop from "features/config/routes/ScrollToTop";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={Store}>
        <Router>
          <ScrollToTop />
          <HelmetProvider>
            <Helmet>
              {/* <!-- Bootstrap core JavaScript--> */}
              <script
                src={process.env.PUBLIC_URL + "/js/jquery.min.js"}
                type="text/javascript"
              ></script>
              <script
                src={process.env.PUBLIC_URL + "/js/bootstrap.bundle.min.js"}
                type="text/javascript"
              ></script>
              {/* <!-- Core plugin JavaScript--> */}
              <script
                src={process.env.PUBLIC_URL + "/js/jquery.easing.min.js"}
                type="text/javascript"
              ></script>
              {/* <!-- Custom scripts for all pages--> */}
            </Helmet>
          </HelmetProvider>
          <App />
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
