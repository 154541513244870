import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios.js";

const initialState = {
  indicatorLineCurrentIndex: 0,
};

export const getAppData = createAsyncThunk("app/get/appData", async () => {
  const response = await axios.get(`/api/v1/customer/getAppData`);
  return response;
});
export const appSlice = createSlice({
  name: "app/appSlice",
  initialState,
  reducers: {
    updateIndicatorLineIndex: (state, action) => {
      state.indicatorLineCurrentIndex = action?.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAppData.pending, (state) => {
        state.appData.loader = true;
      })
      .addCase(getAppData.fulfilled, (state, action) => {
        state.appData.loader = false;
        state.appData.data = action?.payload?.data?.data;
      })
      .addCase(getAppData.rejected, (state) => {
        state.appData.loader = false;
        state.appData.data = [];
      });
  },
});

export const { updateIndicatorLineIndex } = appSlice.actions;

export default appSlice.reducer;
