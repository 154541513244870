const Footer = () => {
  return (
    <footer className="footer-centric">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-6">
            <img
              src="images/centricFiber-logo.svg"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-md-3 col-6">
            <h3>Quick Links</h3>
            <p>
              <a href="#">Builder Service Request</a>
            </p>
            <p>
              <a href="#">Fiber on Demand</a>
            </p>
            <p>
              <a href="#">Download our Apps</a>
            </p>
          </div>
          <div className="col-md-3 col-6">
            <h3>Company</h3>
            <p>
              <a href="#">My Account</a>
            </p>
            <p>
              <a href="#">GigaCall User Portal</a>
            </p>
            <p>
              <a href="#">FAQs</a>
            </p>
            <p>
              <a href="#">Help & Support</a>
            </p>
            <p>
              <a href="#">Contact Us</a>
            </p>
            <p>
              <a href="#">Careers</a>
            </p>
          </div>
          <div className="col-md-3 col-6">
            <h3>legal</h3>
            <p>
              <a href="#">Terms & Conditions</a>
            </p>
            <p>
              <a href="#">Privacy Policy</a>
            </p>
            <p>
              <a href="#">Acceptable Use Policy</a>
            </p>
            <p>
              <a href="#">Open Internet Disclosure Statement</a>
            </p>
            <p>
              <a href="#">Visitor Agreement</a>
            </p>
            <p>
              <a href="#">Web Scam Alert</a>
            </p>
          </div>
        </div>
        <div className="row footer-below justify-content-between">
          <div className="col">
            <span className="text-muted">
              {" "}
              *Currently Centric TV and Streaming services are only available to
              our Florida subscribers.
            </span>
          </div>
          <div className="col text-end">
            <p>Copyright 2024 © Centric Fiber. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
