import { pathToFollowList } from "app/utils/constant";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const indexOfIndicatorLine = pathToFollowList.findIndex(
    (itm) => itm.path === location.pathname
  );
  const addedItems = useSelector((state) => state.addOneSlice.addedItems);
  const selectedPlan = useSelector(
    (state) => state.choosePlaneSlice.selectedPlan
  );
  const cartList = [...addedItems, selectedPlan].filter((i) => i && i !== "");

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand" href="#">
          <img
            src="images/centricFiber-logo.svg"
            width="200"
            alt="Centric Fiber"
          />
        </a>

        <div className="row align-items-center d-lg-none d-block">
          <ul className="navbar-nav navbar-align navicon-centric">
            <li className="nav-item dropdown">
              <a
                className="nav-icon dropdown-toggle"
                href="?#"
                id="alertsDropdown"
                data-bs-toggle="dropdown"
              >
                <div className="position-relative">
                  <img src="images/cart.svg" width="20" alt="" />
                  {cartList.length > 0 && (
                    <span className="indicator">{cartList.length}</span>
                  )}
                </div>
              </a>
              {cartList.length > 0 && (
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
                  aria-labelledby="alertsDropdown"
                  data-bs-popper="none"
                >
                  <div className="dropdown-menu-header">
                    {cartList.length} new products in your cart
                  </div>
                  <div className="list-group">
                    {selectedPlan !== "" && (
                      <a href="#" className="list-group-item">
                        <div className="row g-0 align-items-center">
                          <div className="col-12">
                            <div className="darkblue">1 Gig, $ 66/Month</div>
                            <div className="text-normal small mt-0">
                              Lorem ipsum dolor sit
                            </div>
                            <div className="text-normal small mt-0">
                              30s ago
                            </div>
                          </div>
                        </div>
                      </a>
                    )}
                    {addedItems.length > 0 && (
                      <a href="#" className="list-group-item">
                        <div className="row g-0 align-items-center">
                          <div className="col-12">
                            <div className="darkblue">
                              Wifi Booster, $ 8.50/Month
                            </div>
                            <div className="text-normal small mt-0">
                              Lorem ipsum dolor sit
                            </div>
                            <div className="text-normal small mt-0">
                              20s ago
                            </div>
                          </div>
                        </div>
                      </a>
                    )}
                  </div>
                  <div className="dropdown-menu-footer">
                    <a href="#" className="text-normal">
                      View cart
                    </a>
                  </div>
                </div>
              )}
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-icon pe-md-0 dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
              >
                <img src="images/user.svg" width="25" alt="" />
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href="#">
                  My Account
                </a>
                <a className="dropdown-item" href="#">
                  Customer Resource Center
                </a>
                <a className="dropdown-item" href="#">
                  Contact Us
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  Log out
                </a>
              </div>
            </li>
          </ul>
        </div>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav m-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a className="nav-link" href="#">
                Why Centric
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Internet
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                TV & Streaming
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Phone
              </a>
            </li>
            <li className="nav-item">
              <button className="btn btn-nav">Check Availability</button>
            </li>
          </ul>

          <div className="row align-items-center d-lg-block d-none">
            <ul className="navbar-nav navbar-align">
              <li className="nav-item dropdown">
                <a
                  className="nav-icon dropdown-toggle"
                  href="#"
                  id="alertsDropdown"
                  data-bs-toggle="dropdown"
                >
                  <div className="position-relative">
                    <img src="images/cart.svg" width="20" alt="" />
                    {cartList.length > 0 && (
                      <span className="indicator">{cartList.length}</span>
                    )}
                  </div>
                </a>
                {cartList.length > 0 && (
                  <div
                    className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
                    aria-labelledby="alertsDropdown"
                  >
                    <div className="dropdown-menu-header">
                      {cartList.length} new products in your cart
                    </div>
                    <div className="list-group">
                      {selectedPlan && selectedPlan !== "" && (
                        <a href="#" className="list-group-item">
                          <div className="row g-0 align-items-center">
                            <div className="col-12">
                              <div className="darkblue">1 Gig, $ 66/Month</div>
                              <div className="text-normal small mt-0">
                                Lorem ipsum dolor sit
                              </div>
                              <div className="text-normal small mt-0">
                                30s ago
                              </div>
                            </div>
                          </div>
                        </a>
                      )}
                      {addedItems.length > 0 && (
                        <a href="#" className="list-group-item">
                          <div className="row g-0 align-items-center">
                            <div className="col-12">
                              <div className="darkblue">
                                Wifi Booster, $ 8.50/Month
                              </div>
                              <div className="text-normal small mt-0">
                                Lorem ipsum dolor sit
                              </div>
                              <div className="text-normal small mt-0">
                                20s ago
                              </div>
                            </div>
                          </div>
                        </a>
                      )}
                    </div>
                    <div className="dropdown-menu-footer">
                      <a href="#" className="text-normal">
                        View cart
                      </a>
                    </div>
                  </div>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-icon d-none d-md-block" href="#">
                  <img src="images/search.svg" width="20" alt="" />
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-icon pe-md-0 dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img src="images/user.svg" width="25" alt="" />
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href="#">
                    My Account
                  </a>
                  <a className="dropdown-item" href="#">
                    Customer Resource Center
                  </a>
                  <a className="dropdown-item" href="#">
                    Contact Us
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#">
                    Log out
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Header;
