import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

const errorHandler = (error) => {
  if (error.response.status === 401) {
    window.location = "/session/signin";
  } else {
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong!"
    );
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export default axiosInstance;
